import React from 'react'
import * as classes from './DisentWeDo.module.css'

//pictures
import wheel from '../../images/disent-wheel.svg'
import poster from '../../images/disent-poster.svg'
import browser from '../../images/disent-browser.svg'
import insta from '../../images/disent-insta.svg'

//ads-icons
import adwords from '../../images/disent-traffic-adwords.svg'
import facebook from '../../images/disent-traffic-facebookads.svg'
import apple from '../../images/disent-traffic-apple.svg'
import tikTok from '../../images/disent-traffic-tik-tok.svg'
import yandex from '../../images/disent-traffic-yandex.svg'
import mytarget from '../../images/disent-traffic-mytarget.svg'

//step-icons
import stepOne from '../../images/disent-step-1.svg'
import stepTwo from '../../images/disent-step-2.svg'
import stepThree from '../../images/disent-step-3.svg'
import stepFour from '../../images/disent-step-4.svg'

const steps = [
  { icon: stepOne, name: 'Идея', description: 'Оцениваем перспективы проекта.', number: '01' },
  { icon: stepTwo, name: 'Разработка', description: 'Разрабатываем маркетинговую стратегию.', number: '02' },
  {
    icon: stepThree,
    name: 'Запуск',
    description: 'Настраиваем системы аналитики. Делаем ASO и Soft launch.',
    number: '03',
  },
  {
    icon: stepFour,
    name: 'Продвижение',
    description: 'Запускаем полноценную рекламную кампанию.',
    number: '04',
  },
]

const thecompany = [
  { icon: adwords, name: 'Google ads', path: 'https://ads.google.com/' },
  { icon: facebook, name: 'Facebook ads', path: 'https://ru-ru.facebook.com/business/tools/ads-manager/' },
  { icon: apple, name: 'Apple search ads', path: 'https://searchads.apple.com/' },
  { icon: tikTok, name: 'TikTok ads', path: 'https://www.tiktok.com/business/ru/apps/tiktok/' },
  { icon: yandex, name: 'Yandex direct', path: 'https://direct.yandex.ru/' },
  { icon: mytarget, name: 'myTarget', path: 'https://target.my.com/' },
]

export default function DisentWeDo() {
  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>Услуги продвижения мобильных приложений</h2>
        <p className={classes.description}>Персонально подходим к каждому проекту.</p>
        <div className={classes.work}>
          <div className={classes.work__header}>
            <h3 className={classes.work__title}>Работаем над продуктом на каждом этапе</h3>
            <img className={classes.work__wheel} src={wheel} alt="" />
          </div>
          <ul className={classes.work__steps}>
            {steps.map((step) => (
              <li key={step.name} className={classes.work__step}>
                <div className={classes.step__header}>
                  <img className={classes.step__icon} src={step.icon} alt="" />
                  <span className={classes.step__number}>{step.number}</span>
                </div>
                <div className={classes.step__body}>
                  <h4 className={classes.step__title}>{step.name}</h4>
                  <p className={classes.step__description}>{step.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.offer}>
          <div className={classes.offer__block}>
            <div className={classes.offer__header}>
              <img className={classes.offer__icon} src={poster} alt="" />
              <h3 className={classes.offer__title}>ASO</h3>
            </div>
            <p className={classes.offer__description}>
              Реализуем комплекс работ по оптимизации страниц приложения в Google Play и App Store.
            </p>
            <div className={classes.offer__body}>
              <div className={classes.body__section}>
                <h4 className={classes.body__title}>Зачем нужно ASO?</h4>
                <ul className={classes.body__list}>
                  <li className={classes.body__item}>
                    •&nbsp;&nbsp;Увеличивает количество органических установок.
                  </li>
                  <li className={classes.body__item}>•&nbsp;&nbsp;Улучшает конверсию страницы приложения.</li>
                </ul>
              </div>
              <div className={classes.body__section}>
                <h5 className={classes.body__title}>Что входит:</h5>
                <ul className={classes.body__list}>
                  <li className={classes.body__item}>
                    •&nbsp;&nbsp;Составление семантического ядра, текстовая и графическая оптимизация страниц
                    приложения.
                  </li>
                  <li className={classes.body__item}>
                    •&nbsp;&nbsp;Продвижение в поисковой выдаче Google Play и App Store.
                  </li>
                  <li className={classes.body__item}>
                    •&nbsp;&nbsp;Корректировка отзывов и оценок в Google Play и AppStore.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={classes.offer__block}>
            <div className={classes.offer__header}>
              <img className={classes.offer__icon} src={browser} alt="" />
              <h3 className={classes.offer__title}>Трафик</h3>
            </div>
            <div className={classes.offer__body}>
              <h4 className={classes.body__title}>Запускаем рекламные кампании</h4>
              <p className={classes.body__item}>Используем только качественные источники</p>
              <div className={classes.company__wrap}>
                <ul className={classes.company}>
                  {thecompany.map(
                    (company, index) =>
                      !(index % 2) && (
                        <li key={company.name} className={classes.company__item}>
                          <img src={company.icon} alt="" className={classes.company__icon} />
                          <a className={classes.company__link} href={company.path} target="_blank">
                            •&nbsp;&nbsp;{company.name}
                          </a>
                        </li>
                      )
                  )}
                </ul>
                <ul className={classes.company}>
                  {thecompany.map(
                    (company, index) =>
                      !!(index % 2) && (
                        <li key={company.name} className={classes.company__item}>
                          <img src={company.icon} alt="" className={classes.company__icon} />
                          <a className={classes.company__link} href={company.path} target="_blank">
                            •&nbsp;&nbsp;{company.name}
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>
            <div className={classes.offer__footer}>
              <h5 className={classes.footer__title}>Influence-маркетинг</h5>
              <p className={classes.footer__description}>Сотрудничаем с блогерами</p>
              <div className={classes.footer__links}>
                <a href="https://www.tiktok.com/" target="_blank" className={classes.footer__link}>
                  <img src={tikTok} alt="" className={classes.footer__icon} />
                </a>
                <a href="https://www.instagram.com/" target="_blank" className={classes.footer__link}>
                  <img src={insta} alt="" className={classes.footer__icon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
