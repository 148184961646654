// extracted by mini-css-extract-plugin
export var banner = "DisentBanner-module--banner--07394";
export var button = "DisentBanner-module--button--d1327";
export var description = "DisentBanner-module--description--1b191";
export var logo = "DisentBanner-module--logo--79a9b";
export var main = "DisentBanner-module--main--ec05e";
export var picturebg = "DisentBanner-module--picturebg--52597";
export var picturebg_secondary = "DisentBanner-module--picturebg_secondary--7d15c";
export var rocket = "DisentBanner-module--rocket--f73fb";
export var secondary = "DisentBanner-module--secondary--72b1c";
export var title = "DisentBanner-module--title--d0f50";