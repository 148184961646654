// extracted by mini-css-extract-plugin
export var description = "DisentStartups-module--description--d0378";
export var downloads = "DisentStartups-module--downloads--a47aa";
export var downloads__item = "DisentStartups-module--downloads__item--99348";
export var downloads__text = "DisentStartups-module--downloads__text--17230";
export var downloads_block = "DisentStartups-module--downloads_block--2e168";
export var downloads_block_sum = "DisentStartups-module--downloads_block_sum--07a64";
export var downloads_wrap = "DisentStartups-module--downloads_wrap--9d3f9";
export var header = "DisentStartups-module--header--13dda";
export var header__wrap = "DisentStartups-module--header__wrap--21868";
export var icon = "DisentStartups-module--icon--c2185";
export var mau = "DisentStartups-module--mau--41898";
export var mau_sum = "DisentStartups-module--mau_sum--76230";
export var name = "DisentStartups-module--name--e27cd";
export var picture = "DisentStartups-module--picture--d498f";
export var picture_mt = "DisentStartups-module--picture_mt--f9ab1";
export var section = "DisentStartups-module--section--87315";
export var startup = "DisentStartups-module--startup--c206f";
export var startups = "DisentStartups-module--startups--52333";
export var title = "DisentStartups-module--title--117a2";
export var vector__one = "DisentStartups-module--vector__one--7b8db";
export var vector__three = "DisentStartups-module--vector__three--fecf2";
export var vector__two = "DisentStartups-module--vector__two--7b0f1";
export var vectorbg__one = "DisentStartups-module--vectorbg__one--9d501";
export var vectorbg__two = "DisentStartups-module--vectorbg__two--83c4a";
export var vectors = "DisentStartups-module--vectors--68203";
export var vectors__wrap = "DisentStartups-module--vectors__wrap--cafbc";