import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as classes from './DisentStartups.module.css'
import Img from 'gatsby-image'

//icons
import googleplay from '../../images/disent-googleplay.svg'
import appstore from '../../images/disent-appstore.svg'
import rating from '../../images/disent-rating.svg'
import mau from '../../images/disent-mau.svg'

//vectors
import vectorOne from '../../images/disent-vector-1.svg'
import vectorTwo from '../../images/disent-vector-2.svg'
import vectorThree from '../../images/disent-vector-3.svg'

//vectors-bg
import vectorBgOne from '../../images/disent-vector-bg-1.svg'
import vectorBgTwo from '../../images/disent-vector-bg-2.svg'

export default function DisentStartups() {
  const { startups, pictures, icons } = useStaticQuery(graphql`
    query {
      startups: allDisentstartupsJson {
        edges {
          node {
            name
            description
            mau
            sumMau
            googleplay_downloads
            googleplay_rating
            appstore_downloads
            appstore_rating
            picture
            icon
            googleplayurl
            appstoreurl
          }
        }
      }
      pictures: allFile(filter: { relativePath: { regex: "/disent-startup-/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 338) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      icons: allFile(filter: { relativePath: { regex: "/disent-icon-/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 105, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            publicURL
          }
        }
      }
    }
  `)

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>Наши стартапы</h2>
        <ul className={classes.startups}>
          {startups.edges.map(({ node: startup }, index) => {
            const { node: picture } = pictures.edges.find(
              (picture) => picture.node.relativePath === startup.picture
            )
            console.log(picture)
            const { node: icon } = icons.edges.find((icon) => icon.node.relativePath === startup.icon)

            return (
              <li className={classes.startup} key={startup.name}>
                {!(index % 2) && <img src={vectorBgOne} alt="" className={classes.vectorbg__one} />}
                <div className={`${classes.picture} ${!(index % 2) ? classes.picture_mt : ''}`}>
                  <Img fluid={picture.childImageSharp.fluid} />
                </div>
                <div className={classes.downloads_wrap}>
                  <div
                    className={`${classes.downloads_block} ${
                      !!startup.sumMau ? classes.downloads_block_sum : ''
                    }`}
                  >
                    <div className={classes.header}>
                      <img className={classes.icon} src={icon.publicURL} alt="" />
                      <div className={classes.header__wrap}>
                        <h5 className={classes.name}>{startup.name}</h5>
                        <p className={classes.description}>{startup.description}</p>
                      </div>
                    </div>
                    <ul className={classes.downloads}>
                      {!!startup.googleplay_downloads && (
                        <li className={classes.downloads__item}>
                          <a href={startup.googleplayurl} target="_blank" className={classes.downloads__text}>
                            <img src={googleplay} alt="" />
                            <span>{startup.googleplay_downloads}</span>
                          </a>
                          <div className={classes.downloads__text}>
                            <img src={rating} alt="" />
                            <span>{startup.googleplay_rating}</span>
                          </div>
                        </li>
                      )}
                      {!!startup.appstore_downloads && (
                        <li className={classes.downloads__item}>
                          <a href={startup.appstoreurl} target="_blank" className={classes.downloads__text}>
                            <img src={appstore} alt="" />
                            <span>{startup.appstore_downloads}</span>
                          </a>
                          <div className={classes.downloads__text}>
                            <img src={rating} alt="" />
                            <span>{startup.appstore_rating}</span>
                          </div>
                        </li>
                      )}
                      {!!startup.sumMau && (
                        <li className={classes.mau_sum}>
                          <div className={classes.downloads__text}>
                            <img src={mau} alt="" />
                            <span>{startup.sumMau}</span>
                          </div>
                        </li>
                      )}
                      {!!startup.mau && (
                        <li className={classes.mau}>
                          <div className={classes.downloads__text}>
                            <img src={mau} alt="" />
                            <span>{startup.mau}</span>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  {!startup.sumMau && !(index % 2) && (
                    <div className={classes.vectors}>
                      <img src={vectorOne} className={classes.vector__one} alt="" />
                      <div className={classes.vectors__wrap}>
                        <img src={vectorTwo} className={classes.vector__two} alt="" />
                        <img src={vectorThree} className={classes.vector__three} alt="" />
                      </div>
                    </div>
                  )}
                  {!!startup.sumMau && !!(index % 2) && (
                    <img src={vectorBgTwo} alt="" className={classes.vectorbg__two} />
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}
