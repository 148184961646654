import React, { useRef } from 'react'

import Layout from '../components/Layout'
import DisentBanner from '../components/DisentBanner'
import DisentStartups from '../components/DisentStartups'
import DisentWeDo from '../components/DisentWeDo'
import DisentForm from '../components/DisentForm'

const Disent = () => {
  const anchor = useRef()

  return (
    <Layout
      isDisent={true}
      title="Продвижение мобильных приложений для Android и iOS | Disent"
      content="✅ Продвижение мобильных приложений для Android и iOS от агентства Disent - разработаем и реализуем индивидуальную маркетинговую стратегию для любого проекта."
      ogSiteName="AppCraft"
      ogTitle="“Агентство Disent” | Продвижение мобильных приложений для Android и iOS"
      ogDescription="Продвижение мобильных приложений для Android и iOS от агентства Disent - разработаем и реализуем индивидуальную маркетинговую стратегию для любого проекта."
    >
      <DisentBanner anchor={anchor} />
      <DisentStartups />
      <DisentWeDo />
      <div ref={anchor} />
      <DisentForm />
    </Layout>
  )
}

export default Disent
