import React, { useRef, useState } from 'react'
import * as styles from '../Notification/Notification.module.css'
import * as classes from './DisentForm.module.css'
import { useForm } from 'react-hook-form'
import { schema } from './DisentFromValidation'
import axios from 'axios'

import Notification from '../Notification'

//pictures
import vectorOne from '../../images/disent-vector-bg-1.svg'
import vectorTwo from '../../images/disent-vector-2.svg'
import vectorThree from '../../images/disent-vector-3.svg'
import Loading from '../Loading'

export default function DisentForm({ delay = 300 }) {
  const ref = useRef(null)
  const [isFetching, setisFetching] = useState(false)
  const { handleSubmit, errors, register, watch, reset } = useForm()

  const errorMessage = () => {
    !!ref.current &&
      ref.current(
        <p className={styles.notification_text}>
          Ой, что-то пошло не так &#128532; <br />
          Пожалуйста, повторите попытку или свяжитесь с нами любым удобным способом:
          <br /> по номеру{' '}
          <a href="tel:88002507089" className="zphone header-phone header-phone_disent">
            8(800)250-70-89
          </a>{' '}
          или <br /> по почте{' '}
          <a href="mailto:support@appcraft.pro" className="header-phone header-phone_disent">
            support@appcraft.pro
          </a>
        </p>
      )
  }

  const successMessage = () => {
    !!ref.current &&
      ref.current(
        <p className={styles.notification_text_success}>
          Заявка отправлена! <br />
          Мы свяжемся с Вами в ближайшее время!
        </p>
      )
  }

  const onSubmit = async (result) => {
    setisFetching(true)

    const formData = new FormData()

    formData.set('type', 'request')
    formData.set('name', result.name ? result.name : '')
    formData.set('phone', result.phone ? result.phone : '')
    formData.set(
      'comment',
      result.comment ? `[DISENT] ${result.comment}` : '[DISENT] клиент не оставил комментарий'
    )
    try {
      await axios({
        method: 'POST',
        url: '/send.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      })
      setTimeout(() => {
        successMessage()
        setisFetching(false)
        reset({})
      }, delay)
    } catch (error) {
      setTimeout(() => {
        setisFetching(false)
        errorMessage()
      }, delay)
    }
  }

  return (
    <section className={classes.section}>
      <div className="container">
        <h2 className={classes.title}>Остались вопросы?</h2>
        <p className={classes.description}>Оставьте заявку и мы свяжемся с вами</p>
        <div className={classes.body}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} autoComplete="on">
            <div className={`${classes.field__wrap} ${errors.name ? classes.field__wrap_error : ''}`}>
              <label className={`${classes.label} ${!!watch('name') && classes.label_active}`} for="name">
                Имя
              </label>
              <input
                type="text"
                className={classes.input}
                id="name"
                ref={register(schema.name)}
                name="name"
                autoComplete="off"
              />
              {errors.name && (
                <p className={`text-xs text-danger ${classes.disent_error}`}>{errors.name.message}</p>
              )}
            </div>
            <div className={`${classes.field__wrap} ${errors.phone ? classes.field__wrap_error : ''}`}>
              <label className={`${classes.label} ${!!watch('phone') && classes.label_active}`} for="phone">
                Номер телефона
              </label>
              <input
                type="text"
                className={classes.input}
                id="phone"
                ref={register(schema.phone)}
                name="phone"
                autoComplete="off"
              />
              {errors.phone && (
                <p className={`text-xs text-danger ${classes.disent_error}`}>{errors.phone.message}</p>
              )}
            </div>
            <div className={classes.field__wrap}>
              <label
                className={`${classes.label} ${!!watch('comment') && classes.label_active}`}
                for="comment"
              >
                Сообщение
              </label>
              <input
                type="text"
                className={classes.input}
                id="comment"
                ref={register}
                name="comment"
                autoComplete="off"
              />
            </div>
            <button type="submit" className={`disent-button ${classes.button}`} disabled={isFetching}>
              {isFetching ? <Loading colors={['#fff', '#fff', '#fff']} /> : 'Отправить'}
            </button>
          </form>
          <div className={classes.pictures}>
            <img src={vectorOne} className={classes.pictureOne} alt="" />
            <img src={vectorTwo} className={classes.pictureTwo} alt="" />
            <img src={vectorThree} className={classes.pictureThree} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.notifications}>
        <Notification
          isDisent={true}
          children={(add) => {
            ref.current = add
          }}
        />
      </div>
    </section>
  )
}
