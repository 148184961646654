import { isMobilePhone } from '../../helpers/validation'

export const schema = {
  name: {
    required: 'Укажите имя',
  },
  phone: {
    validate: (value) => isMobilePhone(value) || 'Введите телефон в формате +7 915 100 20 30',
  },
}
