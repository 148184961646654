// extracted by mini-css-extract-plugin
export var body__item = "DisentWeDo-module--body__item--411e8";
export var body__list = "DisentWeDo-module--body__list--a25fd";
export var body__section = "DisentWeDo-module--body__section--ae65b";
export var body__title = "DisentWeDo-module--body__title--60834";
export var company = "DisentWeDo-module--company--8de5e";
export var company__icon = "DisentWeDo-module--company__icon--5970a";
export var company__item = "DisentWeDo-module--company__item--38570";
export var company__link = "DisentWeDo-module--company__link--2008b";
export var company__wrap = "DisentWeDo-module--company__wrap--9037b";
export var description = "DisentWeDo-module--description--7c615";
export var footer__description = "DisentWeDo-module--footer__description--4702f";
export var footer__icon = "DisentWeDo-module--footer__icon--718ad";
export var footer__link = "DisentWeDo-module--footer__link--86a62";
export var footer__links = "DisentWeDo-module--footer__links--c55d7";
export var footer__title = "DisentWeDo-module--footer__title--b9afb";
export var icon = "DisentWeDo-module--icon--fb0e0";
export var offer = "DisentWeDo-module--offer--5e38e";
export var offer__block = "DisentWeDo-module--offer__block--bb919";
export var offer__body = "DisentWeDo-module--offer__body--f97b2";
export var offer__description = "DisentWeDo-module--offer__description--2f8d0";
export var offer__footer = "DisentWeDo-module--offer__footer--4c74f";
export var offer__header = "DisentWeDo-module--offer__header--9e26c";
export var offer__icon = "DisentWeDo-module--offer__icon--866ee";
export var offer__title = "DisentWeDo-module--offer__title--85f4a";
export var rotation = "DisentWeDo-module--rotation--16dcd";
export var section = "DisentWeDo-module--section--3804f";
export var step__body = "DisentWeDo-module--step__body--e5560";
export var step__description = "DisentWeDo-module--step__description--a0cef";
export var step__header = "DisentWeDo-module--step__header--a9de6";
export var step__number = "DisentWeDo-module--step__number--47348";
export var step__title = "DisentWeDo-module--step__title--f972d";
export var title = "DisentWeDo-module--title--7f758";
export var work = "DisentWeDo-module--work--4c96e";
export var work__header = "DisentWeDo-module--work__header--b0be6";
export var work__step = "DisentWeDo-module--work__step--edfb4";
export var work__steps = "DisentWeDo-module--work__steps--bbe47";
export var work__title = "DisentWeDo-module--work__title--08876";
export var work__wheel = "DisentWeDo-module--work__wheel--aaeeb";