import React from 'react'
import * as classes from './DisentBanner.module.css'

import logo from '../../images/disent-logo.svg'
import rocket from '../../images/disent-ellipse-rocket.svg'
import energy from '../../images/disent-ellipse-energy.svg'

export default function DisentBanner({ anchor }) {
  const handleClick = () => {
    !!anchor && anchor.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section className={classes.banner}>
      <div className="container">
        <div className={classes.main}>
          <div className={classes.block}>
            <img className={classes.logo} src={logo} alt="" />
            <h1 className={classes.title}>
              Агентство комплексного продвижения
              <br />
              мобильных приложений
            </h1>
            <p className={classes.description}>
              Разработаем и реализуем индивидуальную маркетинговую стратегию для любого проекта.
            </p>
            <a className={`disent-button ${classes.button}`} onClick={handleClick}>
              Заказать продвижение
            </a>
          </div>
          <div className={classes.picturebg}>
            <img className={classes.rocket} src={rocket} alt="" />
          </div>
        </div>
      </div>
      <div className={classes.secondary}>
        <div className={classes.picturebg_secondary}>
          <img className={classes.rocket} src={energy} alt="" />
        </div>
        <div className="container">
          <h2 className={classes.title}>
            Помогаем развивать <br className="hidden s:block md:hidden" />
            ваши проекты
            <br />и запускаем собственные
          </h2>
          <p className={classes.description}>
            Это позволило получить экспертизу в развитии продуктов со стадии идеи, проработать комплексный
            подход в продвижении приложений.
          </p>
        </div>
      </div>
    </section>
  )
}
